//
// Widgets / Support Tickets
// --------------------------------------------------

.widget-support-tickets {
  .ticket {
    padding: 0 0 14px 0;
    
    
    & + .ticket {
      padding-top: 13px;
      border-top: 1px solid;
      border-top-color: $table-border-color;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  a.ticket-title {
    display: block;
    font-size: 14px;
    padding: 0 90px 0 0;
    line-height: 20px;

    // Ticket icon
    i {
      display: inline-block;
      font-size: 11px;
      margin: 0 5px 0 0;
      top: -1px;
      position: relative;
      text-decoration: none;
    }

    // Ticket number
    span {
      display: inline-block;
      color: #aaa;
      padding-left: 4px;
      text-decoration: none !important;
    }
  }

  .ticket-label {
    display: block;
    float: right;
    font-size: 10px;
    line-height: 18px;
    margin: 2px 0 0 0;
    padding: 0 5px;
    position: relative;
  }

  .ticket-info { 
    color: #aaa;
    font-size: 12px;

    // Username
    a {
      color: $text-color;

      &:hover {
        color: #888;
      }
    }
  }
}

.panel.widget-support-tickets,
.panel .widget-support-tickets {
  .ticket {
    margin: 0 (-$panel-content-padding);
    padding-left: $panel-content-padding;
    padding-right: $panel-content-padding;
  }
}
