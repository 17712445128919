//
// Widgets / Maps
// --------------------------------------------------

.widget-maps {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;


  img {
    max-width: none !important;
  }

  label {
    display:inline !important;
    width: auto !important;
  } 

  .gmnoprint {
    line-height: normal !important;
  }
}
