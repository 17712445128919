//
// Widgets / Profile
// --------------------------------------------------

.widget-profile {
  .panel-heading {
    padding-top: 18px;
    padding-bottom: 16px;
    overflow: hidden;
    position: relative;
    @include clearfix();
  }

  // Profile avatar
  .widget-profile-avatar {
    width: 52px;
    height: 52px;
    border-radius: 999999px;
    float: left;
    margin-right: 12px;
  }

  // Profile header text
  .widget-profile-header {
    float: none;
    overflow: hidden;
    position: relative;
    margin-top: 3px;

    span {
      font-size: 18px;
      font-weight: 300;
      display: inline-block;
    }
  }

  // Background icon
  .widget-profile-bg-icon {
    bottom: -23px;
    width: 100%;
    right: 5px;
    text-align: right;
    position: absolute;

    > * {
      font-size: 100px;
      color: rgba(255, 255, 255, .3);
    }
  }

  // Counters
  .widget-profile-counters {
    text-align: center;

    > div {
      padding: 15px 0;
      font-size: 11px;

      span {
        font-size: 18px;
        font-weight: 700;
        color: #555;
      }
    }

    > div + div {
      border-left: 1px solid $table-border-color;
    }
  }

  // Profile input
  .widget-profile-input {
    border: none;
    border-radius: 0;
  }

  // Profile text
  .widget-profile-text {
    padding: 10px 15px;
  }

  // Borders for counters
  .widget-profile-counters + .widget-profile-input,
  .widget-profile-counters + .widget-profile-text,
  .widget-profile-input + .widget-profile-counters,
  .widget-profile-input + .widget-profile-text,
  .widget-profile-text + .widget-profile-input,
  .widget-profile-text + .widget-profile-counters {
    border-top: 1px solid $table-border-color !important;
  }
}


// Dark panel
//

.panel-dark .widget-profile,
.panel-dark.widget-profile {
  // Profile header text
  .widget-profile-header {
    color: #fff;
    color: rgba(255, 255, 255, .8);

    a {
      color: #fff;
      color: rgba(255, 255, 255, .8);

      &:hover {
        color: #fff;
      }
    }

    span {
      color: #fff;
    }
  }
}


// Centered widget layout
//

.widget-profile.widget-profile-centered {
  text-align: center;

  .widget-profile-avatar {
    width: 70px;
    height: 70px;
    float: none;
    margin: 0 auto 8px auto;
    display: block;
  }
}


// Hide bg icon on IE8
//

.ie8 .widget-profile-bg-icon {
  display: none !important;
}