//
// Widgets / Followers
// --------------------------------------------------

.widget-followers {
  .follower {
    padding: 0 0 10px 0;
    position: relative;

    & + .follower {
      border-top: 1px solid;
      border-top-color: $table-border-color;
      padding-top: 10px;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  .follower-avatar {
    border-radius: 99999px;
    display: block;
    float: left;
    height: 32px;
    margin-right: 10px;
    margin-top: 2px;
    width: 32px;
  }

  .follower-controls {
    float: right;
    margin-top: 4px;
  }

  .follower-controls .btn > i {
    font-size: 14px;
    position: relative;
    top: 1px;
  }

  .body {
    line-height: 17px;
  }

  .follower-name {
    color: #333;
    font-size: 13px;
  }

  .follower-username {
    color: #aaa;
    font-size: 12px;
  }
}

.panel.widget-followers,
.panel .widget-followers {
  .follower {
    margin: 0 (-$panel-content-padding);
    padding-left: $panel-content-padding;
    padding-right: $panel-content-padding;
  }
}
